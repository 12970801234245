// import swal from 'sweetalert2';
import { createStatehook } from 'store/statehook/hookFactory';
import axios from 'axios';
import { statusNotify } from 'utils/kaiAlert';
import { getNS } from '_i18n_';
import _ from 'lodash';
import gotoLoginPageWhenNotAuthorized from './interceptors/gotoLoginPageWhenNotAuthorized';
import webStorageStore from '../store/webStorageStore';

function checkIfAssistanceDomain(url) {
  url = new URL(`${url}`);
  if (process.env.NODE_ENV === 'production') {
    return /^assist.*/.test(url.host);
  }
  return false;
}
function checkIfAssistancePathname(url) {
  url = new URL(`${url}`);
  return /^\/classroom\/assistance_waiting_room.*/.test(url.pathname);
}

const _t = getNS('api/index.js');
// error
// resp: ["config", "request", "response"]
// resp.response: ["data", "status", "statusText", "headers", "config", "request"]

// no internet or timeout
// resp: ["config", "request", "response": undefined]

// normal
// resp: ["data", "status", "statusText", "headers", "config", "request"]

const log = require('debug')('api:api/index.js');

require('promise.prototype.finally').shim();

const BASE_URL = process.env.API_URL || '/api/';
// const BASE_URL = '/api/';
const TIME_OUT = 15 * 1e3; // original 4.5 * 1e3
// following url will not jump to index page when it's authenticate failed.
const theseAPIURLsNoJumpWhen401 = [
  {
    regExp: /account\/authenticate$/gi,
    enabled: true,
  },
];

const api = axios.create({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  headers: { Accept: 'application/json' },
});

api.statehookBox = createStatehook('api', {});

export function setToken(token) {
  // if (api) {
  //   api.defaults.headers.common.Authorization = token;
  //   // Object.assign(api.defaults.headers.common, {
  //   //   get Authorization() {
  //   //     return token;
  //   //   },
  //   // });
  // }
  webStorageStore.setItem('jwt', token);
}

export function removeToken() {
  delete api.defaults.headers.common.Authorization;
  webStorageStore.removeItem('jwt');
}
api.interceptors.request.use(async (config) => {
  config.headers = config.headers || {};
  const authToken = webStorageStore.getItem('jwt');
  if (authToken && authToken !== 'undefined') {
    config.headers.Authorization = authToken;
  }
  api.statehookBox.statehookEmit({
    pathname: config.url,
    actionType: 'request',
    ok: true,
    config,
  });
  return config;
}, (error) => {
  api.statehookBox.statehookEmit({
    pathname: error.config.url,
    actionType: 'request',
    ok: false,
    error,
  });
  return Promise.reject(error);
});

api.interceptors.response.use(async (response) => {
  try {
    const responseURL = _.get(response, 'request.responseURL');
    const { pathname } = new URL(responseURL);
    api.statehookBox.statehookEmit({
      pathname,
      actionType: 'response',
      ok: true,
      response,
    });
  } catch (error) {
    console.reportError(error, 'api.interceptors.response.use succeed');
  }
  return response;
}, async (error) => {
  if (error && error.response && error.response.data) {
    try {
      const responseURL = _.get(error.response, 'request.responseURL');
      const { pathname } = new URL(responseURL);
      api.statehookBox.statehookEmit({
        pathname,
        actionType: 'response',
        ok: false,
        response: error.response,
        error,
      });
    } catch (error2) {
      console.reportError(error2, 'api.interceptors.response.use failed');
    }
  }

  // this function will happend when http code is not 2xx or 3xx
  log(error, 'http request error happend');
  // no network
  if (error.message === 'Network Error') {
    kaiAlert.fire({
      title: _t('No internet connection'),
      text: _t('Check your internet connection'),
      type: 'error',
      timer: 5 * 1e3,
    });
    return Promise.reject(error);
  }
  if (error && error.response && error.response.data) {
    if (error.response.data.error === 'ERROR__ACCOUNT_BEING_ASSISTED') {
      if (!checkIfAssistanceDomain(window.location) && !checkIfAssistancePathname(window.location)) {
        window.location = '/classroom/assistance_waiting_room';
        return Promise.reject(error.response.data.message);
      }
    }
  }
  const requestURL = error.config.url;
  log(requestURL, 'requestURL');
  log(api, 'api');
  log(error.code, 'error.code');
  if (error.code === 'ECONNABORTED') {
    const respConf = error.config;
    statusNotify({
      type: 'error',
      html: `<h3>${error.message || _t('Oops! Something went wrong. Try closing and reopening your browser.')}</h3>
        <strong>${_t('The request which you sent has timeout.')}<br/>${_t('Please, wait few minutes and try again.')}</strong>
        <p>
          <br />
          ${[`time: <u>${Date()}</u>`, `url: <u>${respConf.url}</u>`, `method: <u>${respConf.method}</u>`].join('<br />')}
        </p>`,
      // background: 'orange',
    });
    return Promise.reject(error);
  }
  let isJumpApiWhen401 = true;
  const noJumpKeys = Object.keys(theseAPIURLsNoJumpWhen401);
  while (noJumpKeys.length > 0) {
    const { regExp, enabled } = theseAPIURLsNoJumpWhen401[noJumpKeys.pop()];
    if (regExp.test(requestURL) && enabled) {
      isJumpApiWhen401 = false;
      break;
    }
  }
  if (isJumpApiWhen401) {
    await gotoLoginPageWhenNotAuthorized(error);
  }
  return Promise.reject(error);
});

export default api;
